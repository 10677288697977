<template>
  <div class="profile-page">
    <div class="page-header clear-filter" filter-color="orange">
      <parallax class="page-header-image">
      </parallax>
      <div class="container" v-if="!dataObject.isLoading">
        <div class="photo-container">
          <img :src="getProfilePicture" alt="" />
        </div>

        <h2 class="title p-0 mt-2 mb-2">
          {{ dataObject.profile.extendedProfile.title }}
          {{ dataObject.profile.extendedProfile.firstName }}
          {{ dataObject.profile.extendedProfile.lastName }}
        </h2>
        
        <div class="content">
          <star-rating
            v-model="dataObject.rating"
            read-only
            :show-rating="starConfig.showRating"
            :glow="starConfig.glow"
            :animate="starConfig.animate"
            :star-size="starConfig.size"
            :increment="starConfig.increment">
          </star-rating>
        </div>

        <div class="content">
          <a v-if="facebookLink != null" :href="facebookLink" target="_blank" rel="tooltip" title="Follow me on Facebook">
            <n-button class="btn btn-facebook btn-round btn-lg btn-icon mr-2" social icon round>
              <i class="fab fa-facebook"></i>
            </n-button>
          </a>
          
          <a v-if="instagramLink != null" :href="instagramLink" target="_blank" rel="tooltip" title="Follow me on Instagram">
            <n-button class="btn btn-instagram btn-round btn-lg btn-icon mr-2" social icon round>
              <i class="fab fa-instagram"></i>
            </n-button>
          </a>
          
          <a v-if="twitterLink != null" :href="twitterLink" target="_blank" rel="tooltip" title="Follow me on Twitter">
            <n-button class="btn btn-default btn-round btn-lg btn-icon mr-2" social icon round>
              <i class="fab fa-twitter"></i>
            </n-button>
          </a>
          
          <a v-if="tiktokLink != null" :href="tiktokLink" target="_blank" rel="tooltip" title="Follow me on TikTok">
            <n-button class="btn btn-default btn-round btn-lg btn-icon mr-2" social icon round>
              <i class="fab fa-tiktok"></i>
            </n-button>
          </a>
          
          <a v-if="youtubeLink != null" :href="youtubeLink" target="_blank" rel="tooltip" title="Subscribe to my channel">
            <n-button class="btn btn-default btn-round btn-lg btn-icon mr-2" social icon round>
              <i class="fab fa-youtube"></i>
            </n-button>
          </a>
        </div>

        <div class="about-me">
          <h4 class="title m-0">About me</h4>
          <div class="about-me-text">
            {{ dataObject.profile.extendedProfile.about }}
          </div>
        </div>
      </div>
    </div>
    
    <div class="section" v-if="dataObject.isLoading">
      <div class="container">
        <div class="d-flex flex-row justify-content-center">
          <loading-indicator></loading-indicator>
          <div>&nbsp;Loading profile...</div>
        </div>
      </div>
    </div>
    
    <div class="profile-section section" v-else-if="!dataObject.isLoading">
      <div class="container">
        <div class="button-container">
          <button class="btn btn-primary btn-round btn-lg" outline="" @click="signup"><b>BOOK NOW</b></button>
        </div>
      </div>

      <div class="section p-0">

      <b-container fluid>
        <b-row>
          <b-col cols="12" class="text-center">
            <h3 class="title p-0 m-0 mb-3">Location</h3>
          </b-col>
        </b-row>
        <b-row class="google-map-container">
          <GmapMap class="google-map" :center="dataObject.location.center" :zoom="12" map-type-id="terrain">
            <GmapMarker
              v-for="(m, index) in dataObject.location.markers"
              :key="index"
              :position="m.position"
              :clickable="false"
              :draggable="false" 
              @click="center = m.position" />
          </GmapMap>
        </b-row>
      </b-container>

        <div class="section colleagues pt-5 pb-5" v-if="dataObject.colleagues.length > 0">
          <div class="container">
            <div class="row">
                <div class="col-md-8 mr-auto ml-auto">
                  <h3 class="title p-0 m-0 mb-3">Colleagues</h3>
                  <div class="d-flex justify-content-center flex-wrap align-content-center justify-content-center">
                    <template v-for="(colleague, index) in dataObject.colleagues">
                      <div class="mb-3 col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4" :key="index">
                        <ProfileCard :profileId="colleague.profile.profileId"></ProfileCard>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div class="section associations pt-5 pb-5" data-background-color="black">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h3 class="title p-0 m-0 mb-3">Associations</h3>
                <div class="d-flex justify-content-center flex-wrap">
                  <template v-for="(association, index) in dataObject.practitioner.associations" >
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" :key="index">
                      <div class="card mb-3">
                        <div class="card-body p-3">
                          <h6 class="card-title m-0"><b>{{ association.association.description }}</b></h6>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section qualifications pt-5 pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-8 mr-auto ml-auto">
                <h3 class="title p-0 m-0 mb-3">Qualifications</h3>
                <div class="d-flex justify-content-center flex-wrap">
                  <template v-for="(qualification, index) in dataObject.practitioner.qualifications"  >
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" :key="index">
                      <div class="card mb-3">
                        <div class="card-body p-3">
                          <h4 class="card-title m-0"><b>{{ qualification.institute }} - {{ qualification.year.toString() }}</b></h4>
                          <h6 class="card-subtitle text-muted mb-2">{{ qualification.qualificationType.description }}</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Button } from "@/components";
  import PractitionerService from "@/services/practitionerService";
  import ProfileCard from "@/components/Profile/ProfileCard";
  import StarRating from "vue-star-rating";

  export default {
    name: "profile",
    bodyClass: "profile-page",
    components: {
      ProfileCard,
      StarRating,
      [Button.name]: Button
    },
    computed: {
      getProfilePicture() {
          const missingPicturePlaceholder = "img/shared/profile_picture_placeholder.png";
          if(this.dataObject.profile.profilePictureUrl === undefined || this.dataObject.profile.profilePictureUrl === null) {
              return missingPicturePlaceholder;
          }

          return this.dataObject.profile.profilePictureUrl;
      },
      facebookLink() {
        const self = this;
        const link = self.dataObject.profile.socialLinks.find(
          (socialLink) => socialLink.socialLinkType.description == "Facebook"
        )?.link;

        return link === null || link === undefined ? null : `https://${link}`;
      },
      instagramLink() {
        const self = this;
        const link = self.dataObject.profile.socialLinks.find(
          (socialLink) => socialLink.socialLinkType.description == "Instagram"
        )?.link;

        return link === null || link === undefined ? null : `https://${link}`;
      },
      tiktokLink() {
        const self = this;
        const link = self.dataObject.profile.socialLinks.find(
          (socialLink) => socialLink.socialLinkType.description == "TikTok"
        )?.link;

        return link === null || link === undefined ? null : `https://${link}`;
      },
      youtubeLink() {
        const self = this;
        const link = self.dataObject.profile.socialLinks.find(
          (socialLink) => socialLink.socialLinkType.description == "YouTube"
        )?.link;

        return link === null || link === undefined ? null : `https://${link}`;
      },
      twitterLink() {
        const self = this;
        const link = self.dataObject.profile.socialLinks.find(
          (socialLink) => socialLink?.socialLinkType?.description == "Twitter"
        )?.link;

        return link === null || link === undefined ? null : `https://${link}`;
      },
      rating() {
        const self = this;
        return self.dataObject.practitioner?.rating == null ? 0: self.dataObject.practitioner?.rating.value;
      }
    },
    data: function () {
      return {
        dataObject: {
          isLoading: true,
          colleagues: [],
          profile: {},
          practitioner: {},
          rating: 2.5,
          location: {
            center: { lat: 10, lng: 10 },
            markers: [],
          },
          socialLinks: {
            facebook: null,
            instagram: null,
            twitter: null,
            tiktok: null,
            youtube: null,
          },
        },
        starConfig: {
          glow: 4,
          showRating: false,
          animate: true,
          size: 30,
          increment: 0.5
        },
      };
    },
    methods: {
      getPractitionerById() {
        const self = this;
        self.dataObject.isLoading = true;
        PractitionerService.getPractitionerByProfileId(this.$route.query.profileId, response => {
            self.dataObject.isLoading = false;
            self.processPractitionerInfo(response.data.GetPractitionerByProfileId);
            self.processProfileInfo(response.data.GetProfileById);
            self.processMapData(response.data.GetProfileById);
            self.processColleaguesData(response.data.GetColeaguesByProfileId);
          }
        );
      },
      processPractitionerInfo(requestData) {
        const self = this;
        if (requestData) {
          self.dataObject.practitioner = requestData;
          self.dataObject.rating = requestData.rating === null ? 1 : requestData.rating.value;
        }
      },
      processProfileInfo(requestData) {
        const self = this;
        if (requestData) {
          self.dataObject.profile = requestData;
        }
      },
      processMapData(requestData) {
        const self = this;
        if (requestData) {
          self.dataObject.location.center.lat = requestData.extendedProfile.latitude;
          self.dataObject.location.center.lng = requestData.extendedProfile.longitude;
          
          self.dataObject.location.markers.push({
            position: {
              lat: self.dataObject.location.center.lat,
              lng: self.dataObject.location.center.lng
            }
          });
        }
      },
      processColleaguesData(requestData) {
        const self = this;
        if (requestData) {
          self.dataObject.colleagues = [];
          for(let index = 0; index < requestData.length; index++) {
            if(requestData[index].profile.profileId !== "00000000-0000-0000-0000-000000000000") {
              self.dataObject.colleagues.push(requestData[index]);
            }
          }
        }
      },
      signup() {
          window.location.href = process.env.VUE_APP_SIGNEDINENDPOINT;
      }
    },
    mounted() {
      const self = this;
      self.getPractitionerById();
    },
    watch: {
      "$route.params.profileId": {
        handler(oldUrl, newUrl) {
          const self = this;
          self.getPractitionerById();
        },
        deep: true,
        immediate: true,
      },
    },
    metaInfo() {
        return {
            title: "DrRemo | Practitioner Profile",
            meta: [
                {
                    name: "description",
                    content: "Register an account today and complete your profile."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://dr-remo.co.za/profile"
                }
            ]
        }
    }
  };
</script>