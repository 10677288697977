<template>
  <FadeTransition>
    <loading-indicator v-if="isLoading"></loading-indicator>
    <b-card
      v-if="!isLoading"      
      :title="name"
      :img-src="getProfilePicture"
      :img-alt="name"
      img-top
      tag="article"
      class="profile-card mb-2 text-center h-100"
    >
      <b-button :href="getProfilePicture" variant="primary">View</b-button>
    </b-card>
  </FadeTransition>
</template>
<script>
  import ProfileService from "@/services/profileService";
  import { FadeTransition } from "vue2-transitions";
  export default {
    name: "profile-card",
    props: {
      profileId: String,
    },
    components: {
      FadeTransition
    },
    data() {
      return {
          isLoading: true,
        profile: {},
      };
    },
    computed: {
      name() {
        const self = this;
        return (
          self.profile.extendedProfile?.title +
          " " +
          self.profile.extendedProfile?.firstName +
          " " +
          self.profile.extendedProfile?.lastName
        );
      },
      getProfilePicture() {
          const self = this;
          const missingPicturePlaceholder = "img/shared/profile_picture_placeholder.png";
          if(self.profile.profilePictureUrl === undefined || self.profile.profilePictureUrl === null) {
              return missingPicturePlaceholder;
          }

          return self.profile.profilePictureUrl;
      },
      profileUrl() {
        const self = this;
        return `#/profile?profileId=${self.profile.profileId}`
          
      },
    },
    methods: {
      visibilityChanged(isVisible, entry) {
        const self = this;
        self.isLoading = true;
        if (isVisible) {
          ProfileService.getProfileById(self.profileId, (response) => {
            if (response.data?.result) {
              self.profile = response.data.result;
              self.isLoading = false;
            }
          });
        }
      }
    
    },
    mounted() {
        this.visibilityChanged(true);
    }
  };
</script>