import axios from 'axios'

export class PractitionerService {
    getPractitionerByProfileId(id, handleResponse) {
        axios
            .get(`${process.env.VUE_APP_GATEWAYENDPOINT}/aggregated/practitioner/profile/${id}`)
            .then(response => handleResponse(response));
    }
    
    
    getPractitionersByPracticeId(id, handleResponse) {
        axios
            .get( `${process.env.VUE_APP_GATEWAYENDPOINT}/practitioner/practitioner/get/by/practice/${id}`)
            .then(response => handleResponse(response));
    }
}

export default new PractitionerService();