import axios from 'axios'

export class ProfileService {
    getProfileById(id, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/profile/profile/get/by/profileid/${id}`;
        axios
            .get(endPoint)
            .then(response => handleResponse(response));
    }   
    
}

export default new ProfileService();

